import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import {
  EquipmentGroupDetailsType,
  EquipmentGroupListItemType,
} from '../../types/equipment-group/equipment-group.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import {
  deleteEquipmentGroupDetails,
  getEquipmentGroupDetails,
  getEquipmentGroupsList,
  getEquipmentGroupTypesList,
  getPublicEquipmentGroupsDetails,
  patchEquipmentGroupDetails,
  postEquipmentGroupDetails,
} from './equipmentGroups.action';
import snackbarUtils from '../../utils/SnackbarUtils';
import { EquipmentGroupTypeListItemType } from '../../types/equipment-group/equipment-group-type/equipment-group-type.type';

export interface EquipmentGroupsState {
  equipmentGroupsList: null | PaginationType<EquipmentGroupListItemType>;
  equipmentGroupsListFetchStatus: FETCH_STATUS;
  equipmentGroupTypesList: null | Array<EquipmentGroupTypeListItemType>;
  equipmentGroupTypesListFetchStatus: FETCH_STATUS;
  equipmentGroupsRouteParams: RouteParamsType;
  equipmentGroupDetails: null | EquipmentGroupDetailsType;
  equipmentGroupDetailsFetchStatus: FETCH_STATUS;
  equipmentGroupDetailsPostStatus: FETCH_STATUS;
  equipmentGroupDetailsPatchStatus: FETCH_STATUS;
  equipmentGroupDetailsDeleteStatus: FETCH_STATUS;
  publicEquipmentGroupDetails: null | EquipmentGroupDetailsType;
  publicEquipmentGroupDetailsFetchStatus: FETCH_STATUS;
}

export const initialEquipmentGroupsRouteParams = {
  page: 1,
  limit: 10,
  order: '-equipment_group.id',
  q: '',
  status: '',
  in_service: 'all',
  country_id: '',
  country_category: '',
};

const INIT_STATE: EquipmentGroupsState = {
  equipmentGroupsRouteParams: initialEquipmentGroupsRouteParams,
  equipmentGroupsList: null,
  equipmentGroupsListFetchStatus: FETCH_STATUS.NULL,
  equipmentGroupDetails: null,
  equipmentGroupDetailsFetchStatus: FETCH_STATUS.NULL,
  equipmentGroupDetailsPostStatus: FETCH_STATUS.NULL,
  equipmentGroupDetailsPatchStatus: FETCH_STATUS.NULL,
  equipmentGroupDetailsDeleteStatus: FETCH_STATUS.NULL,
  equipmentGroupTypesList: null,
  equipmentGroupTypesListFetchStatus: FETCH_STATUS.NULL,
  publicEquipmentGroupDetails: null,
  publicEquipmentGroupDetailsFetchStatus: FETCH_STATUS.NULL,
};

export const equipmentGroupsSlice = createSlice({
  name: 'equipmentGroups',
  initialState: INIT_STATE,
  reducers: {
    setEquipmentGroupsList: (state, action) => {
      state.equipmentGroupsList = action.payload;
    },
    setEquipmentGroupsListFetchStatus: (state, action) => {
      state.equipmentGroupsListFetchStatus = action.payload;
    },
    setEquipmentGroupDetails: (state, action) => {
      state.equipmentGroupDetails = action.payload;
    },
    setEquipmentGroupDetailsFetchStatus: (state, action) => {
      state.equipmentGroupDetailsFetchStatus = action.payload;
    },
    setEquipmentGroupDetailsPostStatus: (state, action) => {
      state.equipmentGroupDetailsPostStatus = action.payload;
    },
    setEquipmentGroupDetailsPatchStatus: (state, action) => {
      state.equipmentGroupDetailsPatchStatus = action.payload;
    },
    setEquipmentGroupDetailsDeleteStatus: (state, action) => {
      state.equipmentGroupDetailsDeleteStatus = action.payload;
    },
    setEquipmentGroupTypesList: (state, action) => {
      state.equipmentGroupTypesList = action.payload;
    },
    setEquipmentGroupTypesListFetchStatus: (state, action) => {
      state.equipmentGroupTypesListFetchStatus = action.payload;
    },
    setPublicEquipmentGroupsDetails: (state, action) => {
      state.publicEquipmentGroupDetails = action.payload;
    },
    setPublicEquipmentGroupsDetailsFetchStatus: (state, action) => {
      state.publicEquipmentGroupDetailsFetchStatus = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'equipmentGroups') {
        state.equipmentGroupsRouteParams = { ...state.equipmentGroupsRouteParams, ...payload.data };
      }
    });

    // CREATE BATTERIES DETAILS
    builder.addCase(postEquipmentGroupDetails.pending, state => {
      state.equipmentGroupDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postEquipmentGroupDetails.rejected, (state, action) => {
      state.equipmentGroupDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postEquipmentGroupDetails.fulfilled, state => {
      state.equipmentGroupDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Equipment Group created successfully');
      });
    });

    // GET BATTERIES LIST
    builder.addCase(getEquipmentGroupsList.pending, state => {
      state.equipmentGroupsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getEquipmentGroupsList.rejected, (state, action) => {
      if (action.payload) {
        state.equipmentGroupsListFetchStatus = FETCH_STATUS.REJECTED;
        // state.equipmentGroupsList = null;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getEquipmentGroupsList.fulfilled, (state, action) => {
      state.equipmentGroupsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.equipmentGroupsList = action.payload;
    });

    // GET BATTERIES TYPES LIST
    builder.addCase(getEquipmentGroupTypesList.pending, state => {
      state.equipmentGroupTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getEquipmentGroupTypesList.rejected, (state, action) => {
      state.equipmentGroupTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.equipmentGroupTypesList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getEquipmentGroupTypesList.fulfilled, (state, action) => {
      state.equipmentGroupTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.equipmentGroupTypesList = action.payload;
    });

    // GET BATTERIES DETAILS
    builder.addCase(getEquipmentGroupDetails.pending, state => {
      state.equipmentGroupDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getEquipmentGroupDetails.rejected, (state, action) => {
      state.equipmentGroupDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.equipmentGroupDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getEquipmentGroupDetails.fulfilled, (state, action) => {
      state.equipmentGroupDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.equipmentGroupDetails = action.payload;
    });

    // PATCH BATTERIES DETAILS
    builder.addCase(patchEquipmentGroupDetails.pending, state => {
      state.equipmentGroupDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchEquipmentGroupDetails.rejected, (state, action) => {
      state.equipmentGroupDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchEquipmentGroupDetails.fulfilled, state => {
      state.equipmentGroupDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Equipment Group updated successfully');
      });
    });

    // DELETE BATTERIES DETAILS
    builder.addCase(deleteEquipmentGroupDetails.pending, state => {
      state.equipmentGroupDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteEquipmentGroupDetails.rejected, (state, action) => {
      state.equipmentGroupDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteEquipmentGroupDetails.fulfilled, state => {
      state.equipmentGroupDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Equipment Group deleted successfully');
      });
    });

    // GET PUBLIC EQUIPMENT GROUP DETAILS
    builder.addCase(getPublicEquipmentGroupsDetails.pending, state => {
      state.publicEquipmentGroupDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPublicEquipmentGroupsDetails.rejected, (state, action) => {
      state.publicEquipmentGroupDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.publicEquipmentGroupDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getPublicEquipmentGroupsDetails.fulfilled, (state, action) => {
      state.publicEquipmentGroupDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.publicEquipmentGroupDetails = action.payload;
    });
  },
});

export const {
  setEquipmentGroupDetailsDeleteStatus,
  setEquipmentGroupDetailsFetchStatus,
  setEquipmentGroupDetailsPatchStatus,
  setEquipmentGroupDetailsPostStatus,
  setEquipmentGroupsListFetchStatus,
  setEquipmentGroupDetails,
  setEquipmentGroupsList,
  setEquipmentGroupTypesListFetchStatus,
  setEquipmentGroupTypesList,
  setPublicEquipmentGroupsDetailsFetchStatus,
  setPublicEquipmentGroupsDetails,
} = equipmentGroupsSlice.actions;

export default equipmentGroupsSlice.reducer;
