import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { RootState } from '../../redux/Store';
import { additionalQueryParams } from '../../utils/utilities';
import { setShowModal, setModalContent } from '../utils/utils.slice';
import {
  CreateOrganizationDto,
  OrganizationDetailsType,
  OrganizationListItemType,
  OrganizationMapStatsType,
  OrganizationType,
  UpdateOrganizationDto,
} from '../../types/organization/organization.type';
import { CREDENTIAL_TYPES } from '../../utils/constants';
import { getCurrentUser } from '../auth/auth.action';
import { getDomain } from '../shared/shared.action';

export const postOrganizationDetails = createAsyncThunk<
  OrganizationType,
  { data: CreateOrganizationDto },
  { state: RootState }
>('organizations/postOrganizationDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<OrganizationType>(`${process.env.REACT_APP_API_URL}/organizations`, data);
    thunkAPI.dispatch(getCurrentUser({ init: false }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating organization!');
  }
});

export const getOrganizationsList = createAsyncThunk<
  PaginationType<OrganizationListItemType>,
  RouteParamsType & { controller: AbortController },
  { state: RootState }
>('organizations/getOrganizationsList/no-loader', async (data, thunkAPI) => {
  try {
    const { controller, ...params } = data;
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      null,
      thunkAPI.getState().sharedReducer.includeSuborgs,
      thunkAPI.getState().sharedReducer.selectedCountry
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');
    const response = await axios.get<PaginationType<OrganizationListItemType>>(
      `${process.env.REACT_APP_API_URL}/organizations/short-list${queryString ? `?${queryString}` : ''}`,
      { signal: controller?.signal }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching organizations!');
  }
});

export const getOrganizationDetails = createAsyncThunk<OrganizationDetailsType, { id: number }, { state: RootState }>(
  'organizations/getOrganizationDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<OrganizationDetailsType>(`${process.env.REACT_APP_API_URL}/organizations/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching organization!');
    }
  }
);

export const patchOrganizationDetails = createAsyncThunk<
  {
    org: OrganizationType;
    alerts: {
      parentHasChanged: boolean;
    };
  },
  { id: number; data: UpdateOrganizationDto },
  { state: RootState }
>('organizations/patchOrganizationDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      org: OrganizationType;
      alerts: {
        parentHasChanged: boolean;
      };
    }>(`${process.env.REACT_APP_API_URL}/organizations/${id}`, data);
    const domainData = thunkAPI.getState().sharedReducer.domainData.id;
    if (+domainData.id === +id) {
      thunkAPI.dispatch(getDomain({ domain: domainData.domain }));
    }
    thunkAPI.dispatch(getCurrentUser({ init: false }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating organization!');
  }
});

export const deleteOrganizationDetails = createAsyncThunk<
  OrganizationType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('organizations/deleteOrganizationDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<OrganizationType>(`${process.env.REACT_APP_API_URL}/organizations/${id}`);
    if (fetchList)
      thunkAPI.dispatch(getOrganizationsList(thunkAPI.getState().organizationsReducer.organizationsRouteParams));
    thunkAPI.dispatch(getCurrentUser({ init: false }));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting organization!');
  }
});

export const getOrganizationMapStats = createAsyncThunk<OrganizationMapStatsType, undefined, { state: RootState }>(
  'organizations/getOrganizationMapStats',
  async (params, thunkAPI) => {
    try {
      let queryString = '';
      const selectedCompany = thunkAPI.getState().sharedReducer.selectedCompany;
      const selectedCountry = thunkAPI.getState().sharedReducer.selectedCountry;
      if (selectedCompany.id !== 'all') {
        queryString += `?org_id=${selectedCompany.id}${selectedCountry ? `&country_id=${selectedCountry.id}` : ''}`;
      } else {
        queryString += `?inc_sub=true${selectedCountry ? `&country_id=${selectedCountry.id}` : ''}`;
      }

      const response = await axios.get<OrganizationMapStatsType>(
        `${process.env.REACT_APP_API_URL}/organizations/stats${queryString ? queryString : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching organization map stats!');
    }
  }
);

export const patchOrganizationBobo = createAsyncThunk<
  void,
  { id: number; data: { bobo_enabled: boolean } },
  { state: RootState }
>('organizations/patchOrganizationBobo', async ({ id, data }, thunkAPI) => {
  try {
    const response = await axios.patch<void>(`${process.env.REACT_APP_API_URL}/organizations/${id}/bobo`, data);
    thunkAPI.dispatch(getOrganizationDetails({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing organization!');
  }
});

export const patchRemoveOrganizationCredentials = createAsyncThunk<
  void,
  { id: number | string; type: string },
  { state: RootState }
>('organizations/patchRemoveOrganizationCredentials', async ({ id, type }, thunkAPI) => {
  try {
    const response = await axios.patch<void>(`${process.env.REACT_APP_API_URL}/organizations/${id}/removeCredentials`, {
      type,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during removing credentials!');
  }
});

export const postVerifyOrganizationCredentials = createAsyncThunk<
  void,
  { id: number; type: string; data: unknown },
  { state: RootState }
>('organizations/postVerifyOrganizationCredentials', async ({ id, data, type }, thunkAPI) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}`;
    if (type === CREDENTIAL_TYPES.InHand) {
      url += `/organizations/${id}/verifyInhandCredentials`;
    } else if (type === CREDENTIAL_TYPES.Cradlepoint) {
      url += `/organizations/${id}/verifyCradlepointCredentials`;
    } else if (type === CREDENTIAL_TYPES.Pepwave) {
      url += `/organizations/${id}/verifyPepwaveCredentials`;
    } else if (type === CREDENTIAL_TYPES.SierraWireless) {
      url += `/organizations/${id}/verifySierraWirelessCredentials`;
    }
    const response = await axios.patch<void>(url, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Credentials are invalid!');
  }
});

export const postFixOrganizationRevIo = createAsyncThunk<{ success: boolean }, { id: number }, { state: RootState }>(
  'organizations/postFixOrganizationRevIo',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.post<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/organizations/${id}/fix-revio`, undefined);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fixing organization Rev.io!');
    }
  }
);

export const postMultipleOrgFiles = createAsyncThunk<
  { success: boolean },
  { data: unknown; params: any; providedURL?: string },
  { state: RootState }
>('organizations/postMultipleOrgFiles', async ({ data, params, providedURL }, thunkAPI) => {
  try {
    const qs = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}${providedURL || '/org-files/uploadFiles'}?${qs}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during uploading file!');
  }
});

export const patchOrgFile = createAsyncThunk<{ success: boolean }, { id: number; data: unknown }, { state: RootState }>(
  'organizations/patchOrgFile',
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axios.patch<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/org-files/${id}`, data);

      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during uploading file!');
    }
  }
);

export const deleteOrgFile = createAsyncThunk<{ success: boolean }, { id: number }, { state: RootState }>(
  'organizations/deleteOrgFile',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/org-files/${id}`);

      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting file!');
    }
  }
);

export const postOrgNote = createAsyncThunk<{ success: boolean }, { data: unknown }, { state: RootState }>(
  'organizations/postOrgNote',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/org-note/`, data);

      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating note!');
    }
  }
);

export const patchOrgNote = createAsyncThunk<{ success: boolean }, { id: number; data: unknown }, { state: RootState }>(
  'organizations/patchOrgNote',
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axios.patch<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/org-note/${id}`, data);

      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing note!');
    }
  }
);

export const deleteOrgNote = createAsyncThunk<{ success: boolean }, { id: number }, { state: RootState }>(
  'organizations/deleteOrgNote',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/org-note/${id}`);

      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting note!');
    }
  }
);
