import { createSlice } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { WarehouseDetailsType, WarehouseListItemType } from '../../types/warehouse/warehouse.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import {
  deleteWarehouseDetails,
  getWarehouseDetails,
  getWarehousesList,
  patchWarehouseDetails,
  postWarehouseDetails,
} from './warehouses.action';
import snackbarUtils from '../../utils/SnackbarUtils';

export interface WarehousesState {
  warehousesList: null | PaginationType<WarehouseListItemType>;
  warehousesListFetchStatus: FETCH_STATUS;
  warehousesRouteParams: RouteParamsType;
  warehouseDetails: null | WarehouseDetailsType;
  warehouseDetailsFetchStatus: FETCH_STATUS;
  warehouseDetailsPostStatus: FETCH_STATUS;
  warehouseDetailsPatchStatus: FETCH_STATUS;
  warehouseDetailsDeleteStatus: FETCH_STATUS;
}

export const initialWarehousesRouteParams = {
  page: 1,
  limit: 10,
  order: '-warehouse.id',
  q: '',
  country_id: '',
  country_category: '',
};

const INIT_STATE: WarehousesState = {
  warehousesRouteParams: initialWarehousesRouteParams,
  warehousesList: null,
  warehousesListFetchStatus: FETCH_STATUS.NULL,
  warehouseDetails: null,
  warehouseDetailsFetchStatus: FETCH_STATUS.NULL,
  warehouseDetailsPostStatus: FETCH_STATUS.NULL,
  warehouseDetailsPatchStatus: FETCH_STATUS.NULL,
  warehouseDetailsDeleteStatus: FETCH_STATUS.NULL,
};

export const warehousesSlice = createSlice({
  name: 'warehouses',
  initialState: INIT_STATE,
  reducers: {
    setWarehousesList: (state, action) => {
      state.warehousesList = action.payload;
    },
    setWarehousesListFetchStatus: (state, action) => {
      state.warehousesListFetchStatus = action.payload;
    },
    setWarehouseDetails: (state, action) => {
      state.warehouseDetails = action.payload;
    },
    setWarehouseDetailsFetchStatus: (state, action) => {
      state.warehouseDetailsFetchStatus = action.payload;
    },
    setWarehouseDetailsPostStatus: (state, action) => {
      state.warehouseDetailsPostStatus = action.payload;
    },
    setWarehouseDetailsPatchStatus: (state, action) => {
      state.warehouseDetailsPatchStatus = action.payload;
    },
    setWarehouseDetailsDeleteStatus: (state, action) => {
      state.warehouseDetailsDeleteStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'warehouses') {
        state.warehousesRouteParams = { ...state.warehousesRouteParams, ...payload.data };
      }
    });

    // CREATE WAREHOUSES DETAILS
    builder.addCase(postWarehouseDetails.pending, state => {
      state.warehouseDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postWarehouseDetails.rejected, (state, action) => {
      state.warehouseDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(postWarehouseDetails.fulfilled, state => {
      state.warehouseDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Warehouse created successfully');
      });
    });

    // GET WAREHOUSES LIST
    builder.addCase(getWarehousesList.pending, state => {
      state.warehousesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getWarehousesList.rejected, (state, action) => {
      if (action.payload) {
        state.warehousesListFetchStatus = FETCH_STATUS.REJECTED;
        // state.warehousesList = null;
        setTimeout(() => {
          snackbarUtils.error(action.payload as string);
        });
      }
    });
    builder.addCase(getWarehousesList.fulfilled, (state, action) => {
      state.warehousesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.warehousesList = action.payload;
    });

    // GET WAREHOUSES DETAILS
    builder.addCase(getWarehouseDetails.pending, state => {
      state.warehouseDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getWarehouseDetails.rejected, (state, action) => {
      state.warehouseDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.warehouseDetails = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getWarehouseDetails.fulfilled, (state, action) => {
      state.warehouseDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.warehouseDetails = action.payload;
    });

    // PATCH WAREHOUSES DETAILS
    builder.addCase(patchWarehouseDetails.pending, state => {
      state.warehouseDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchWarehouseDetails.rejected, (state, action) => {
      state.warehouseDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(patchWarehouseDetails.fulfilled, state => {
      state.warehouseDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Warehouse updated successfully');
      });
    });

    // DELETE WAREHOUSES DETAILS
    builder.addCase(deleteWarehouseDetails.pending, state => {
      state.warehouseDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteWarehouseDetails.rejected, (state, action) => {
      state.warehouseDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(deleteWarehouseDetails.fulfilled, state => {
      state.warehouseDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Warehouse deleted successfully');
      });
    });
  },
});

export const {
  setWarehousesList,
  setWarehousesListFetchStatus,
  setWarehouseDetailsFetchStatus,
  setWarehouseDetailsDeleteStatus,
  setWarehouseDetailsPatchStatus,
  setWarehouseDetailsPostStatus,
  setWarehouseDetails,
} = warehousesSlice.actions;

export default warehousesSlice.reducer;
